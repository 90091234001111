import focus from "@alpinejs/focus";
import Alpine from "alpinejs";
import { animate, glide, spring } from "motion";
import Typed from "typed.js";

// Internal Modules
import "./modules/nav";
import "./modules/theme";
import "./modules/orama";

// Instantiate Alpine.js
Alpine.plugin(focus);
Alpine.start();

window.Alpine = Alpine;

animate(
  "#hero-avatar",
  { x: 0, y: 0 },
  {
    x: { easing: glide({ velocity: -130, from: 100, to: 0 }) },
    y: { easing: spring({ velocity: 500 }) },
  },
);

new Typed("#hero-title", {
  strings: ["Hi! I'm Aris Ripandi."],
  typeSpeed: 40,
  loop: false,
  contentType: "html",
  showCursor: false,
});

const heroSummaryElement = document.getElementById("hero-summary");
const heroSummaryStrings = [
  'These days, I\'m working as a <span class="font-bold text-primary-500">senior product engineer</span> at <a href="https://zero-one-group.com/?ref=ripandis.com" target="_blank" rel="noopener" class="bg-gradient-to-r from-primary-400 to-teal-600 bg-clip-text font-bold text-transparent hover:text-black/80">Zero One Group</a>.',
  'I\'m pretty passionate about teaching and love tinkering with <span class="font-bold text-indigo-500">Open Source</span> stuff.',
  "I enjoy keeping up with the latest in web development - always trying to learn something new!",
  '<br/><br/>When I\'m not knee-deep in code, you might catch me contributing to open-source projects or jotting down some tech thoughts on <a href="/blog" class="bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text font-bold text-transparent hover:text-black/80">my blog</a>.',
];

heroSummaryStrings.forEach((heroSummaryString, index) => {
  const span = document.createElement("span");
  heroSummaryElement.appendChild(span);

  new Typed(span, {
    strings: [heroSummaryString],
    typeSpeed: 30,
    // Adjust this value to control the delay between each string
    startDelay: index === 0 ? 1200 : index * 3000,
    showCursor: false,
    contentType: "html",
    loop: false,
  });

  if (index < heroSummaryStrings.length - 1) {
    heroSummaryElement.appendChild(document.createTextNode(" "));
  }
});
